import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { sprinkles } from '../sprinkles.css';

// each teammember needs to have a unique nickname
// for this nickname /images/team/<nickname>.jpeg has to exist
// and there has to be a key in locales/{en,de}/TeamPage.json

const core = ['Martin', 'Fred', 'Arianne', 'Alexei', 'Nico'];
const advisory = ['Alex'];

const PersonSection = ({
	nickName,
	image,
}: {
	nickName: string;
	image?: IGatsbyImageData;
}) => {
	const { t } = useTranslation('TeamPage', { keyPrefix: nickName });
	return (
		<Section id={nickName}>
			<Title level="subsection">{t('Name')}</Title>
			<Section
				align="start"
				direction="horizontal"
				gap="medium"
				wrap={false}
				justify="right"
			>
				<Section align="start" gap="medium">
					<Text variant="primary">
						<span className={sprinkles({ color: 'primary' })}>
							<strong>{t('Position')}</strong>
						</span>
					</Text>
					<Text variant="primary">
						<Trans ns="TeamPage" i18nKey={nickName + '.Description_html'} />
					</Text>
				</Section>
				{image && (
					<GatsbyImage
						style={{ borderRadius: '256px', flexGrow: 2 }}
						image={image}
						alt={t('Name')}
					/>
				)}
			</Section>
		</Section>
	);
};

const TeamPage = ({
	data: {
		allFile: { edges },
	},
}: {
	data: {
		allFile: {
			edges: {
				node: {
					name: string;
					childImageSharp: { gatsbyImageData: IGatsbyImageData };
				};
			}[];
		};
	};
}) => {
	const { t } = useTranslation('TeamPage');

	return (
		<Layout>
			<Section gap="larger">
				<Section align="start">
					<Title level="title">{t('Title')}</Title>
					<Text align="left" variant="primary">
						<Trans ns="TeamPage" key="Description_html">
							{t('Description_html')}
						</Trans>
					</Text>
				</Section>
				<Section gap="huge">
					{core.map((name) => (
						<PersonSection
							key={name}
							nickName={name}
							image={
								edges.find((e) => e.node.name === name)?.node.childImageSharp
									.gatsbyImageData
							}
						/>
					))}
				</Section>
				<Title level="section">{t('TitleAdvisors')}</Title>
				<Section gap="giant">
					{advisory.map((name) => (
						<PersonSection
							key={name}
							nickName={name}
							image={
								edges.find((e) => e.node.name === name)?.node.childImageSharp
									.gatsbyImageData
							}
						/>
					))}
				</Section>
			</Section>
		</Layout>
	);
};

export const Head = () => {
	const { t } = useTranslation('TeamPage');
	return <Seo title={t('Title') + ' | konfidal'} />;
};

export default TeamPage;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, relativePath: { glob: "team/*" } }
		) {
			edges {
				node {
					relativePath
					name
					childImageSharp {
						gatsbyImageData(width: 256, height: 256, placeholder: BLURRED)
					}
				}
			}
		}
	}
`;
